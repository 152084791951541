import { 
  UPDATE_CARD_DETAILS,
  FETCH_CARD_DETAILS, 
  MAKE_CARD_PRIMARY,
  DELETE_CARD,
  VALIDATE_BANK_ACCOUNT,
  FETCH_BANK_ACCOUNTS,
  SET_BANK_PRIMARY,
  DELETE_BANK_ACCOUNT,
  FETCH_BANKS,
  FETCH_PAYOUTS
} from "../types/transactionTypes";
import { LOGOUT_USER } from "../types/authTypes";

const INITIAL_STATE = {
  cards: [],
  bank: [],
  transData: {},
  banks: [],
  payouts: [],
};

const transactionReducer = (state = INITIAL_STATE, action) => {

  const makePrimary = (state, action) => {
    const updatedCard = action.payload;
    let currentCardlist = state.cards.slice(0);
    let formerDefault = currentCardlist.map(x => x.default).indexOf(true);
    currentCardlist[formerDefault].default = false;
    const updatedCardList = [];
    currentCardlist.forEach((element) => {
      if (element.id === updatedCard.id) {
        updatedCardList.push(action.payload);
      } else updatedCardList.push(element);
    });
    return updatedCardList;
  };

  const deleteCard = (state, action) => {
    const currentCardlist = state.cards.slice(0);
    const updatedCardList = currentCardlist.filter(item => item.id !== action.payload);
    return updatedCardList;
  };

  const makePrimaryBank = (state, action) => {
    const updatedBank = action.payload;
    let currentBanklist = state.bank.slice(0);
    let formerDefault = currentBanklist.map(x => x.default).indexOf(true);
    currentBanklist[formerDefault].default = false;
    const updatedBankList = [];
    currentBanklist.forEach((element) => {
      if (element.id === updatedBank.id) {
        updatedBankList.push(action.payload);
      } else updatedBankList.push(element);
    });
    return updatedBankList;
  };

  const deleteBank = (state, action) => {
    const currentBanklist = state.bank.slice(0);
    const updatedBankList = currentBanklist.filter(item => item.id !== action.payload);
    return updatedBankList;
  };

  switch (action.type) {
    case FETCH_CARD_DETAILS:
      return {
        ...state,
        cards: action.payload
      };
    case UPDATE_CARD_DETAILS:
      return {
        ...state,
        cards: [...state.cards, action.payload]
      };
    case MAKE_CARD_PRIMARY:
      return {
        ...state,
        cards: makePrimary(state, action)
      };
    case DELETE_CARD:
      return {
        ...state,
        cards: deleteCard(state, action)
      };
    case VALIDATE_BANK_ACCOUNT:
      return {
        ...state,
        bank: [...state.bank, action.payload]
      };
    case FETCH_BANK_ACCOUNTS:
      return {
        ...state,
        bank: action.payload
      };
    case SET_BANK_PRIMARY:
      return {
        ...state,
        bank: makePrimaryBank(state, action)
      };
    case DELETE_BANK_ACCOUNT:
      return {
        ...state,
        bank: deleteBank(state, action)
      };
    case FETCH_BANKS:
      return {
        ...state,
        banks: action.payload
      };
    case FETCH_PAYOUTS:
      return {
        ...state,
        payouts: action.payload
      };
    case LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default transactionReducer;
