import {
    GET_CHANNEL,
    GET_USER_CHANNELS,
    GET_MEMBER_CHANNELS,
    CREATE_CHANNEL,
    GET_CHANNEL_THREADS,
    GET_CHANNEL_MEMBERS,
    INVITE_USERS_TO_CHANNEL,
    ACCEPT_INVITATION,
    DECLINE_INVITATION,
    GET_INVITATION,
    GET_CHANNEL_INVITATIONS,
    JOIN_CHANNEL,
    APPROVE_REQUEST,
    REJECT_REQUEST,
    UPDATE_CHANNEL
} from "../types/channelsTypes";
import { LOGOUT_USER } from "../types/authTypes";


const INITIAL_STATE = {
    created: [],
    joined: [],
    requests: {},
    invitations: {},
    threads: {},
    comments: {},
    members: {},
    active: {},
};

const channelsReducer = (state = INITIAL_STATE, action) => {

  let channel_id, updatedInvitations, updatedRequests;

  switch (action.type) {

    case GET_USER_CHANNELS:
        return {
          ...state,
          created: action.payload,
        };

    case GET_MEMBER_CHANNELS:
        return {
          ...state,
          joined: action.payload,
        };

    case CREATE_CHANNEL:
        return {
          ...state,
          created: [...state.created, action.payload],
        };

    case GET_CHANNEL:

        return {
          ...state,
          active: action.payload,
        };

    case GET_CHANNEL_THREADS:
          channel_id = action.payload.channelId;

          return {
            ...state,
            threads: {
              ...state.threads,
              [channel_id]: action.payload.threads,
            },
          };

    case GET_CHANNEL_MEMBERS:

      const cid = action.payload.channelId;

      return {
        ...state,
        members: {
          ...state.members,
          [cid]: action.payload.data,
        },
      };

    case GET_CHANNEL_INVITATIONS:
      return {
        ...state,
        invitations: {
          ...state.invitations,
          [action.payload.channel_id]: action.payload.data,
        },
      };

    case INVITE_USERS_TO_CHANNEL:
      channel_id = action.payload.channel_id;
    
      // Extract successful invitations
      const successful = action.payload.successful;
    
      // Check if the channel_id already exists in the invitations object
      const existingInvitations = state.invitations[channel_id] || [];
    
      // Update existing invitations with successful ones
      const updatedSuccessfulInvitations = successful.map(newInvitation => {
        const existingInvitation = existingInvitations.find(
          invitation => invitation.invitation_id === newInvitation.invitation_id
        );
        return existingInvitation ? { ...existingInvitation, ...newInvitation } : newInvitation;
      });
    
      // Update state with new invitations
      const updatedInvitations = {
        ...state.invitations,
        [channel_id]: updatedSuccessfulInvitations,
      };
    
      return {
        ...state,
        invitations: updatedInvitations,
      };      
    
    case ACCEPT_INVITATION:
    case DECLINE_INVITATION:
    case GET_INVITATION:
      channel_id = action.payload.data.channel_id;

      // Check if the channel_id already exists in the invitations object
      if (state.invitations[channel_id]) {
        // If the channel_id exists, update the invitation for that channel
        updatedInvitations = {
          ...state.invitations,
          [channel_id]: state.invitations[channel_id].map(invitation =>
            invitation.invitation_id === action.payload.data.invitation_id ? action.payload.data : invitation
          ),
        };
      } else {
        // If the channel_id doesn't exist, add a new entry with the invitation
        updatedInvitations = {
          ...state.invitations,
          [channel_id]: [action.payload.data],
        };
      }

      return {
        ...state,
        invitations: updatedInvitations,
      };

      case APPROVE_REQUEST:
      case REJECT_REQUEST:
      channel_id = action.payload.data.channel_id;

      // Check if the channel_id already exists in the invitations object
      if (state.requests[channel_id]) {
        // If the channel_id exists, update the invitation for that channel
        updatedRequests = {
          ...state.requests,
          [channel_id]: state.requests[channel_id].map(request =>
            request.request_id === action.payload.data.request_id ? action.payload.data : request
          ),
        };
      } else {
        // If the channel_id doesn't exist, add a new entry with the invitation
        updatedRequests = {
          ...state.requests,
          [channel_id]: [action.payload.data],
        };
      }

      return {
        ...state,
        requests: updatedRequests,
      };

    case JOIN_CHANNEL:

      const joinStatus = action.payload.status;
      if(joinStatus==="pending"){
         return {
            ...state,
            requests: [
              ...state.requests,
              action.payload.request,
            ],
          };
      } else {
         return {
           ...state,
           joined: [
             ...state.joined,
             action.payload.channel,
           ],
         };
      }

    case UPDATE_CHANNEL:
      const updatedChannel = action.payload.data;
      const updatedCreated = state.created.map(channel =>
        channel.channel_id === action.payload.channel_id ? updatedChannel : channel
      );
      const updatedJoined = state.joined.map(channel =>
        channel.channel_id === action.payload.channel_id ? updatedChannel : channel
      );

      return {
        ...state,
        created: [...updatedCreated],
        joined: [...updatedJoined],
      };

    case LOGOUT_USER:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default channelsReducer;

