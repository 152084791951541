import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import ErrorBoundary from "../components/ui/ErrorBoundary";

const PrivateRoute = ({
  component: PrivateComponent,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        // if user is authenticated show request component
        <ErrorBoundary>
          <PrivateComponent {...props} />
        </ErrorBoundary>
      ) : (
        // enable this line below to make route protected
        <Redirect to="/" />
      )
    }
  />
);

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired
};

const mapSateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated
});

export default connect(mapSateToProps, {})(PrivateRoute);
