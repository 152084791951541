export const GET_THREAD = 'GET_THREAD';
export const GET_THREADS = 'GET_THREADS';
export const GET_MY_THREADS = 'GET_MY_THREADS';
export const GET_COMMENTS = 'GET_COMMENTS';
export const GET_REPLIES = 'GET_REPLIES';
export const GET_MENTIONS = 'GET_MENTIONS';
export const UPDATE_THREAD = 'UPDATE_THREAD';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const CREATE_THREAD = 'CREATE_THREAD';
export const GET_THREAD_TOP_CONTRIBUTORS = 'GET_THREAD_TOP_CONTRIBUTORS';
export const GET_TOP_CONTRIBUTORS = 'GET_TOP_CONTRIBUTORS';
export const GET_SORTED_THREADS = 'GET_SORTED_THREADS';
export const GET_TOP_THREADS_BY_CHANNEL = 'GET_TOP_THREADS_BY_CHANNEL';
export const GET_TRENDING = 'GET_TRENDING';
export const SEARCH_THREADS = 'SEARCH_THREADS';
export const ADD_VIEW = 'ADD_VIEW';
export const GET_USER_THREAD_LIKES = 'GET_USER_THREAD_LIKES';
export const GET_USER_COMMENT_LIKES = 'GET_USER_COMMENT_LIKES';
export const GET_USER_COMMENT_REPLIES = 'GET_USER_COMMENT_REPLIES';
export const ADD_FLAG = 'ADD_FLAG';
export const UPDATE_THREAD_STATUS = 'UPDATE_THREAD_STATUS';
