import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import ErrorBoundary from "../components/ui/ErrorBoundary";

const PublicRoute = ({
  component: AuthComponent,
  isAuthenticated,
  isLoading,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated ? (
        // if user is note authenticated show loading component
        <ErrorBoundary>
          <AuthComponent {...props} isLoading={isLoading} />
        </ErrorBoundary>
      ) : (
        // enable this line below to make route protected
        <Redirect to="/home" />
      )
    }
  />
);

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired
};

const mapStateToProps = ({ loading, auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  isLoading: loading.isLoading
});

export default connect(mapStateToProps, {})(PublicRoute);
