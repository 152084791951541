import { LOGOUT_USER } from "../types/authTypes";

const initialState = {
    balanceHidden: true,
    canGoBack: false,
};

const othersReducer = (state = initialState, action) => {
  switch (action.type) {

    case "UPDATE_BALANCE_HIDDEN":
        return{
            ...state,
            balanceHidden: action.payload
        };
    
    case "UPDATE_BACK_BUTTON_STATE":
        return{
            ...state,
            canGoBack: action.payload
        };

    case LOGOUT_USER:
      return initialState;

    default:
      return state;
  }
};

export default othersReducer;
