const BASE_URL = {
    production: "https://api.mookie.app", // "https://mookie-api-prod.herokuapp.com",
    staging: "https://api-test.mookie.app", // "https://mookie-api.herokuapp.com",
    dev: "https://mookie-api.herokuapp.com",
};
  
export const MIXPANEL_KEY = {
    dev: typeof process !== 'undefined' ? process.env.REACT_APP_MIXPANEL_KEY_DEV : null,
    staging: typeof process !== 'undefined' ? process.env.REACT_APP_MIXPANEL_KEY_STAGING : null,
    production: typeof process !== 'undefined' ? process.env.REACT_APP_MIXPANEL_KEY_PRODUCTION : null
};

  
export default BASE_URL;