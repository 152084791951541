import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import auth  from "./authReducer";
import loading from "./loadingReducer";
import subscription from "./subscriptionReducer";
import booking from "./bookingReducer";
import transaction from "./transactionReducer";
import sse from "./sseReducer";
import threads from "./threadsReducer";
import channels from "./channelsReducer";
import modal from "./modalReducer";
import dashboard from "./dashboardReducer";
import sports from "./sportsReducer";
import scroll from "./scrollReducer";
import others from "./othersReducer";

export const appReducer = combineReducers({
  auth,
  loading,
  subscription,
  booking,
  transaction,
  sse,
  threads,
  channels,
  modal,
  dashboard,
  sports,
  scroll,
  others
});

const rootReducer = (state, action) => {
  if (action.type === "USER_CLEAR") {
    storage.removeItem("persist:root");
    localStorage.clear();
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;