import { UPDATE_MODAL_STATE, REQUEST_OPEN_MODAL } from "../types/modalTypes";
import { LOGOUT_USER } from "../types/authTypes";

const initialState = {
  modals: [{ modal: "notifications", isOpen: false },
    { modal: "view_all_channels", isOpen: false },
    { modal: "invite_users", isOpen: false },
    { modal: "create_channel", isOpen: false },
    { modal: "convert", isOpen: false },
    { modal: "share_channel", isOpen: false },
    { modal: "new_subscription", isOpen: false },
    { modal: "custom_modal", isOpen: false },
  ],
  requested_modal: '',
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MODAL_STATE:
      const { modal, isOpen } = action.payload;
      const updatedModals = state.modals.map((item) => {
        if (item.modal === modal) {
          return { ...item, isOpen };
        }
        return item;
      });

      return {
        ...state,
        modals: updatedModals,
      };

    case REQUEST_OPEN_MODAL:
      return {
        ...state,
        requested_modal: action.payload,
      };

    case LOGOUT_USER:
      return initialState;

    default:
      return state;
  }
};

export default modalReducer;
