export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGGED_IN_USER = 'LOGGED_IN_USER';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const CLOSE_DEACTIVATION_MODAL = 'CLOSE_DEACTIVATION_MODAL';
export const CLOSE_REACTIVATION_MODAL = 'CLOSE_REACTIVATION_MODAL';
export const SET_REACTIVATED = 'SET_REACTIVATED';
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const UPDATE_USER_COINS = "UPDATE_USER_COINS";
export const NETWORK_ERROR_OCCURRED = "NETWORK_ERROR_OCCURRED";
export const RESET_NETWORK_ERROR = "RESET_NETWORK_ERROR";
export const UPDATE_PROFILE = "UPDATE_PROFILE";