import { CLOSE_DEACTIVATION_MODAL, CLOSE_REACTIVATION_MODAL, SET_REACTIVATED, DELETE_ACCOUNT, LOGGED_IN_USER, LOGOUT_USER, UPDATE_PROFILE, UPDATE_USER_AVATAR, UPDATE_USER_COINS, NETWORK_ERROR_OCCURRED, RESET_NETWORK_ERROR } from "../types/authTypes";

const INITIAL_STATE = {
  isAuthenticated: false,
  refreshToken: null,
  userData: {},
  deleted: false,
  reactivated: false,
  coins: 0,
  experience: 0,
  networkError: false,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGED_IN_USER:
      return {
        ...state,
        userData: {...state.userData, ...action.payload },
        isAuthenticated: true,
        coins: action.payload.coins,
        experience: action.payload.experience,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        userData: {...state.userData, ...action.payload },
        isAuthenticated: true,
      };
    case UPDATE_USER_AVATAR:
      return {
        ...state,
        userData: {...state.userData, photo_url: action.payload },
      };
    case UPDATE_USER_COINS:
      return {
        ...state,
        coins: action.payload.coins,
        experience: action.payload.experience,
      };
    case LOGOUT_USER:
      return INITIAL_STATE;
    case DELETE_ACCOUNT:
      return {
        isAuthenticated: false,
        refreshToken: null,
        userData: {},
        deleted: true
      };
    case CLOSE_DEACTIVATION_MODAL:
      return {
        ...state,
        deleted: false,
      };
     case CLOSE_REACTIVATION_MODAL:
      return {
        ...state,
        reactivated: false,
      };
     case SET_REACTIVATED:
      return {
        ...state,
        reactivated: true,
      };
     case NETWORK_ERROR_OCCURRED:
      return {
        ...state,
        networkError: true,
      };
     case RESET_NETWORK_ERROR:
      return {
        ...state,
        networkError: false,
      };
    default:
      return state;
  }
};

export default authReducer;