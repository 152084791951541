export const GET_CHANNEL = 'GET_CHANNEL';
export const GET_CHANNELS = 'GET_CHANNELS';
export const GET_USER_CHANNELS = 'GET_USER_CHANNELS';
export const GET_MEMBER_CHANNELS = 'GET_MEMBER_CHANNELS';
export const CREATE_CHANNEL = 'CREATE_CHANNEL';
export const INVITE_USERS_TO_CHANNEL = 'INVITE_USERS_TO_CHANNEL';
export const GET_CHANNEL_THREADS = 'GET_CHANNEL_THREADS';
export const GET_CHANNEL_MEMBERS = 'GET_CHANNEL_MEMBERS';
export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
export const DECLINE_INVITATION = 'DECLINE_INVITATION';
export const GET_INVITATION = 'GET_INVITATION';
export const GET_CHANNEL_INVITATIONS = 'GET_CHANNEL_INVITATIONS';
export const JOIN_CHANNEL = 'JOIN_CHANNEL';
export const APPROVE_REQUEST = 'APPROVE_REQUEST';
export const REJECT_REQUEST = 'REJECT_REQUEST';
export const UPDATE_CHANNEL = 'UPDATE_CHANNEL';




