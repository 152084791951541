import React from 'react';
import Loader from 'react-loader-spinner';

const ContentLoader = React.memo(props => (
  <div
    style={
      props.style || {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%'
      }
    }
  >
    <Loader
      type={props.type || 'Puff'}
      color="#C734E5"
      height={props.h || 60}
      width={props.w || 60}
    />
  </div>
));
export default ContentLoader;

export const AppLoader = props => (
  <Loader
    type="Puff"
    color="#FFFFFF"
    width={props.width || '76'}
    height={props.height || '76'}
  />
);

export const ButtonLoader = (props) => (
    <div
    className=" self-center"
        style={
        props.style || {
            margin: 'auto',
            width: '15%',
        }
        }
    >
        <Loader
            type="Puff"
            color={props.color || "#FFFFFF"}
            width={props.width || 'auto'}
            height={props.height || '20'}
        />
    </div>
);